/* SPDX-License-Identifier: AGPL-3.0-or-later */
window.addEventListener("popstate", () => {
  console.log("Back button pressed")
})

function getQuery () {
  return document.querySelector("#q").value
}

function loadRegistry () {
  try {
    return JSON.parse(localStorage.getItem("click_registry") || "[]");
  } catch (e) {
    console.warn("Registry corrupted")
    console.warn(e)
  }

  return []
}

function saveRegistry (clickRegistry) {
  localStorage.setItem("click_registry", JSON.stringify(clickRegistry))
}

function getResultUrl (article) {
  return article.querySelector("a").href
}

function getHostname (article) {
  return new URL(getResultUrl(article)).hostname
}

function loadBannedDomains () {
  try {
    return JSON.parse(localStorage.getItem("banned_domains") || "[]")
  } catch (e) {
    console.warn("Banned domains are corrupted")
    console.warn(e)
  }

  return []
}

function saveBannedDomains (domains) {
  localStorage.setItem("banned_domains", JSON.stringify(domains))
}

function banDomain (hostname) {
  const bannedDomains = loadBannedDomains()
  bannedDomains.push(hostname)
  saveBannedDomains(bannedDomains)
}

function registerUrlClick (url) {
  try {
    new URL(url)
  } catch (e) {
    console.warn("Tried to register invalid URL")
    console.warn(url)
    return
  }

  const now = Date.now().valueOf()

  let clickRegistry = loadRegistry();

  // Remove registered clicks that happened recently on the same page
  // This way we remove clicks that ended up not being what the user was looking for
  clickRegistry = clickRegistry.filter((oldClick) => oldClick.query != getQuery())

  clickRegistry.push({
    query: getQuery(),
    url_clicked: url,
    timestamp: now
  })

  saveRegistry(clickRegistry)
}

function clickRegistryCleanup () {
  let clickRegistry = loadRegistry();

  // Remove previous clicks that happened recently on this page
  // clickRegistry = clickRegistry.filter(({ query }) => query == getQuery())

  clickRegistry = clickRegistry.filter(({ query, url_clicked, timestamp }) => {
    const TEN_MINUTES = 1000 * 60 * 10;
    if (Date.now() - timestamp > TEN_MINUTES) {
      // This click survived in the registry for long enough
      // which means the user likely found what they were looking for.
      // We promote this domain in the future. And remove it from the registry

      try {
        registerPromotion(new URL(url_clicked).hostname)
      } catch (e) {
        console.warn("Tried to promote invalid url:", url_clicked)
      }
      return false
    }

    return true
  })

  saveRegistry(clickRegistry)
}

function loadPromotions () {
  try {
    return JSON.parse(localStorage.getItem("url_promotions") || "{}")
  } catch (e) {
    console.warn("URL promotions corrupted")
    console.warn(e)
  }

  return {}
}

function savePromotions (promotions) {
  localStorage.setItem("url_promotions", JSON.stringify(promotions))
}

function registerPromotion (hostname) {
  let promotions = loadPromotions();

  if (promotions[hostname] == undefined) {
    promotions[hostname] = 1
  } else {
    promotions[hostname] += 1
  }

  savePromotions(promotions)
}

function removePromotion (hostname) {
  let promotions = loadPromotions();
  promotions[hostname] = undefined
  savePromotions(promotions)
}

function doPromotion () {
  const promotions = loadPromotions();

  const resultsDiv = document.querySelector("#urls")

  let resultsToPromote = Array.from(document.querySelectorAll(".result")).filter(result => {
    return Object.keys(promotions).includes(getHostname(result))
  })

  resultsToPromote.sort((a, b) => {
    promotions[getHostname(a)] - promotions[getHostname(b)]
  })

  let dedupedResults = []
  let seenDomains = []
  for (const result of resultsToPromote) {
    const hostname = getHostname(result)
    if (seenDomains.includes(hostname)) {
      continue
    }

    dedupedResults.push(result)
    seenDomains.push(hostname)
  }
  resultsToPromote = dedupedResults

  resultsToPromote.forEach(result => {
    result.parentElement.removeChild(result)
  })

  resultsDiv.prepend(document.createElement("hr"))

  resultsToPromote.forEach(result => {
    resultsDiv.prepend(result)

    const i = result.dataset.originalRanking
    const engines = result.querySelector(".engines")
    if (i && engines) {
      const rankingElement = document.createElement("span")
      rankingElement.style.float = "left"
      rankingElement.innerHTML = "#" + i
      engines.prepend(rankingElement)

      const forgetButton = document.createElement("a")
      forgetButton.classList.add("cache_link")
      forgetButton.innerHTML = "forget"
      forgetButton.href = "#"
      forgetButton.addEventListener("click", ev => {
        ev.preventDefault()
        const hostname = getHostname(result)
        if (confirm("Are you sure you want to stop promoting " + hostname + "?")) {
          removePromotion(hostname)
        }
      })
      engines.append(forgetButton)
    }
  })
}

function doBannedDomains () {
  const bannedDomains = loadBannedDomains();
  const urls = document.querySelector("#urls")

  document.querySelectorAll(".result").forEach(result => {
    if (bannedDomains.includes(getHostname(result))) {
      urls.removeChild(result)
    }
  })
}

(function (w, d, searxng) {
  'use strict';


  if (searxng.endpoint !== 'results') {
    return;
  }

  searxng.ready(function () {
    // Push clicks to the click register
    document.querySelectorAll(".result a").forEach(result => {
      result.addEventListener("click", ev => {
        registerUrlClick(ev.target.href)
      })
    })

    document.querySelectorAll(".result").forEach((result, i) => {
      const engines = result.querySelector(".engines")
      result.dataset.originalRanking = i + 1

      const separator = document.createElement("span")
      separator.innerHTML = "|"
      separator.style.margin = "0 0 0 .5rem";
      engines.appendChild(separator)

      const banButton = document.createElement("a")
      banButton.innerHTML = "ban"
      banButton.href = "#"
      banButton.classList.add("cache_link")
      banButton.addEventListener("click", e => {
        e.preventDefault()
        const hostname = getHostname(result)
        if (confirm("Are you sure you want to ban " + hostname + " from search results?")) {
          banDomain(hostname)
        }
      })
      engines.appendChild(banButton)
    })

    // Load old clicks from register and see if user returned
    clickRegistryCleanup();
    doPromotion();
    doBannedDomains();

    d.querySelectorAll('#urls img.image').forEach(
      img =>
        img.addEventListener(
          'error', () => {
            img.style.display = 'none';
            img.error = null;
          }
        ));

    if (d.querySelector('#search_url button#copy_url')) {
      d.querySelector('#search_url button#copy_url').style.display = "block";
    }

    searxng.on('.btn-collapse', 'click', function () {
      var btnLabelCollapsed = this.getAttribute('data-btn-text-collapsed');
      var btnLabelNotCollapsed = this.getAttribute('data-btn-text-not-collapsed');
      var target = this.getAttribute('data-target');
      var targetElement = d.querySelector(target);
      var html = this.innerHTML;
      if (this.classList.contains('collapsed')) {
        html = html.replace(btnLabelCollapsed, btnLabelNotCollapsed);
      } else {
        html = html.replace(btnLabelNotCollapsed, btnLabelCollapsed);
      }
      this.innerHTML = html;
      this.classList.toggle('collapsed');
      targetElement.classList.toggle('invisible');
    });

    searxng.on('.media-loader', 'click', function () {
      var target = this.getAttribute('data-target');
      var iframe_load = d.querySelector(target + ' > iframe');
      var srctest = iframe_load.getAttribute('src');
      if (srctest === null || srctest === undefined || srctest === false) {
        iframe_load.setAttribute('src', iframe_load.getAttribute('data-src'));
      }
    });

    searxng.on('#copy_url', 'click', function () {
      var target = this.parentElement.querySelector('pre');
      navigator.clipboard.writeText(target.innerText);
      this.innerText = this.dataset.copiedText;
    });

    searxng.selectImage = function (resultElement) {
      /* eslint no-unused-vars: 0 */
      if (resultElement) {
        // load full size image in background
        const imgElement = resultElement.querySelector('.result-images-source img');
        const thumbnailElement = resultElement.querySelector('.image_thumbnail');
        const detailElement = resultElement.querySelector('.detail');
        if (imgElement) {
          const imgSrc = imgElement.getAttribute('data-src');
          if (imgSrc) {
            const loader = d.createElement('div');
            const imgLoader = new Image();

            loader.classList.add('loader');
            detailElement.appendChild(loader);

            imgLoader.onload = e => {
              imgElement.src = imgSrc;
              loader.remove();
            };
            imgLoader.onerror = e => {
              loader.remove();
            };
            imgLoader.src = imgSrc;
            imgElement.src = thumbnailElement.src;
            imgElement.removeAttribute('data-src');
          }
        }
      }
      d.getElementById('results').classList.add('image-detail-open');
      searxng.scrollPageToSelected();
    }

    searxng.closeDetail = function (e) {
      d.getElementById('results').classList.remove('image-detail-open');
      searxng.scrollPageToSelected();
    }
    searxng.on('.result-detail-close', 'click', e => {
      e.preventDefault();
      searxng.closeDetail();
    });
    searxng.on('.result-detail-previous', 'click', e => {
      e.preventDefault();
      searxng.selectPrevious(false)
    });
    searxng.on('.result-detail-next', 'click', e => {
      e.preventDefault();
      searxng.selectNext(false);
    });

    w.addEventListener('scroll', function () {
      var e = d.getElementById('backToTop'),
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop,
        results = d.getElementById('results');
      if (e !== null) {
        if (scrollTop >= 100) {
          results.classList.add('scrolling');
        } else {
          results.classList.remove('scrolling');
        }
      }
    }, true);

  });

})(window, document, window.searxng);
